import { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import _IconForward from '@mui/icons-material/ArrowForward';

import AppContext from 'Context';
import langData from 'common/lang_data';
import Grid from 'common/grid';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: 2,
    width: {
      sx: '100%',
      md: '75%',
    },
  },
  btnLink: {
    fontSize: 24,
  }
};

const Welcome = () => {
  const { getLang } = useContext(AppContext);
  const data = langData[getLang()];

  return (
    <Box sx={styles.root}>
      <Typography variant="h1">{data.title}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Typography>
            {data.col1}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography>{data.col2}</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{data.col3}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Welcome;
