const data = {
  title: 'Jedite zdravo, Živite dobro',
  col1: (
    <>
      Hraniti ljude nije posao, to je poziv.
      <br />
      A zdrava, ukusna, jednostavna hrana, pripremljena od svežih, prirodnih
      sastojaka za nas nije trend i nije biznis - to je naš život.
      <br />
      To je hrana koju pravimo sebi i svojima, i koju delimo sa vama. Jer, i vi
      ste naši.
    </>
  ),
  col2: (
    <>
      Tu, u Šoljici, gde slavimo život i mala, svakodnevna zadovoljstva.
      <br />
      Tu, gde su i stari i mladi u najboljim godinama.
      <br />
      Tu, gde se srećemo sa prijateljima, komšijama i poznanicima, i gde se
      upoznajemo sa strancima, slučajnim prolaznicima i putnicima.
    </>
  ),
  col3: (
    <>
      Sa onima koji su baš gladni i onima koji bi samo malo, onako da probaju.
      <br />
      Tu, gde naši gosti postaju naši prijatelji. U Šoljici.
    </>
  ),
  food: 'Hrana',
  drink: 'Piće',
};

export default data;
