import { Routes, Route, Navigate } from 'react-router-dom';
import { useState } from 'react';
import { Box } from '@mui/material';

import { Welcome, Categories, Header, Items, Menu } from 'components';

import Loader from 'common/loader';
import ErrorAlert from 'common/error_alert';

import { useMenu } from 'apollo/hooks';
import { useAppContext } from 'common/hooks';

const msgLoading = 'Učitavam meni...';
const msgError = 'Greška pri učitavanju menija';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  main: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'start',
  },
};

const App = () => {
  const { getLang, languages, setLang } = useAppContext();
  const [currentLang, setCurrentLang] = useState(getLang());
  const { loading, error, data: { menu, types } = {} } = useMenu(currentLang);

  const onChangeLang = code => {
    setLang(code);
    setCurrentLang(code);
  };

  const LangRedirect = ({ lang }) => {
    onChangeLang(lang);
    return <Navigate to="/" replace />;
  };

  return (
    <Box sx={styles.root}>
      <Header {...{ languages, onChangeLang }} />
      <Menu types={types} />
      {loading && <Loader message={msgLoading} />}
      {!loading && error && <ErrorAlert message={msgError} error={error} />}
      {!loading && !error && (
        <Box sx={styles.main}>
          <Routes>
            {languages.map(({ code }) => (
              <Route
                key={`lang_redirect_${code}`}
                path={`/${code}`}
                element={<LangRedirect lang={code} />}
              />
            ))}
            <Route path="/t/:id/:type" element={<Categories menu={menu} />} />
            {/* <Route
              path="/food"
              element={<Categories type="food" menu={menu} />}
            /> */}
            <Route path="/:type/:id" element={<Items menu={menu} />} />
            <Route path="/:type/:id/:item_id" element={<Items menu={menu} />} />
            <Route
              index
              element={<Welcome {...{ onChangeLang, languages }} />}
            />
          </Routes>
        </Box>
      )}
    </Box>
  );
};

export default App;
