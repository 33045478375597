import { createContext } from 'react';
import languages from './languages.json';

const AppContext = createContext();

const setLang = code => window.localStorage.setItem('lang', code);
const getLang = () => {
  let lang = window.localStorage.getItem('lang');
  if (!lang) {
    lang = (languages || [])[0]?.code;
    if (lang) setLang(lang);
  }
  return lang;
}

const contextData = {
  languages,
  setLang,
  getLang,
};

export const AppContextProvider = ({ children }) => (
  <AppContext.Provider value={contextData}>{children}</AppContext.Provider>
);

export default AppContext;
