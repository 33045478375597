import { Box, CircularProgress, Typography } from "@mui/material";

const styles = {
  root: {
    textAlign: 'center',
  },
  message: {
    marginTop: 2,
  },
};

const Loader = ({ message = null, size = 24 }) =>
  <Box sx={styles.root}>
    <CircularProgress size={size} />
    {message && <Typography sx={styles.message}>{message}</Typography>}
  </Box>

export default Loader;
