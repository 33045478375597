import { useNavigate } from 'react-router-dom';
import { Box, Link } from '@mui/material';

// import langData from 'common/lang_data';
// import { useAppContext } from 'common/hooks';
import theme from 'common/theme';

const buildStyles = ({ palette }) => ({
  root: {
    display: 'flex',
    width: '100%',
    padding: '8px 0',
    background: '#f5f5f5',
    justifyContent: 'center',
    alignItemst: 'center',
  },
  link: {
    fontSize: 18,
    textTransform: 'uppercase',
    marginRight: 2,
    '&:last-of-type': {
      marginRight: 0,
    },
    color: 'black',
    textDecoration: 'none',
    cursor: 'pointer',
    padding: '4px 8px',
    borderRadius: 1,
    fontWeight: 600,
  },
  selected: {
    background: '#fff',
    color: palette.primary.main,
  },
});

const Menu = ({ types = [] }) => {
  const navigate = useNavigate();
  const styles = buildStyles(theme);
  const path = window.location.pathname;


  const goto = e => {
    e.preventDefault();
    navigate(e.target.getAttribute('href'));
  }

  const escapeName = name => name.toLowerCase().replace(/[\s]+/, '-');

  const styleSelected = (id, _name) => {
    if (path.match(`^/t/${id}`)) return styles.selected;

    return {};
  };

  return (
    <Box sx={styles.root}>
      {(types || []).map(({ id, name }) => (
        <Link
          key={`menu-item-${name}`}
          href={`/t/${id}/${escapeName(name)}`}
          onClick={goto}
          children={name}
          sx={{ ...styles.link, ...styleSelected(id, name) }}
        />
      ))}
      {/* <Link
        href="/food"
        onClick={goto}
        children={data.food}
        sx={{ ...styles.link, ...selFood }}
      />
      <Link
        href="/drink"
        onClick={goto}
        children={data.drink}
        sx={{ ...styles.link, ...selDrink }}
      /> */}
    </Box>
  );
};

export default Menu;
