const { createTheme, responsiveFontSizes } = require('@mui/material');

const Theme = createTheme({
  palette: {
    primary: {
      main: '#ef4d08ff',
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: ['Alegreya'],
    h1: {
      textTransform: 'uppercase',
      fontSize: 36,
      fontWeight: 600,
      marginBottom: 10,
    },
    h2: {
      textTransform: 'uppercase',
      fontSize: 24,
      fontWeight: 600,
    },
    h4: {
      fontSize: 20,
    },
  },
});

export default responsiveFontSizes(Theme);
