import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import Grid from 'common/grid';
import Item from './item';
import styles from '../styles.json';

const Food = ({ menu }) => {
  const { id: categoryId } = useParams();
  const category = menu.find(({ id }) => id === parseInt(categoryId));
  const { items = [], name } = category || {};

  return (
    <Box sx={styles.categoryContainer}>
      <Typography sx={styles.categoryTitle} variant="h1" color="primary">
        {name}
      </Typography>
      <Grid container spacing={2} sx={styles.container}>
        {items.map(item => (
          <Grid item xs={12} sm={6} md={4} key={`item_${item.id}`}>
            <Item {...item} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Food;
