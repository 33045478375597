import { Box, Typography } from '@mui/material';
import styles from '../styles.json';
import Item from './item';

const Drink = ({ menu, type }) => {
  const items = menu.filter(({ type: { id } }) => id === type?.id);

  return (
    <Box>
      {items.map(({ id, name, description, items = [] }) => (
        <Box key={`drink-${id}`} sx={styles.categoryContainer}>
          <Typography sx={styles.categoryTitle} variant="h1" color="primary">
            {name}
          </Typography>
          {description && (
            <Typography sx={styles.categoryDescription}>
              {description}
            </Typography>
          )}
          {items.map(item => (
            <Item key={`item-${item?.id}`} item={item} />
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default Drink;
