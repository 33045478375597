import { Link, useNavigate } from 'react-router-dom';
import { Avatar, Box, Chip } from '@mui/material';

import { ReactComponent as Logo } from 'soljica.svg';

import Flags from 'common/flags';
import config from '../config';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 2,
    width: '100%',

    '& >*': {
      display: 'flex',
      margin: 1,
    },
  },
  lang: {
    margin: '0 3px',
  },
};

const Header = ({ languages }) => {
  const navigate = useNavigate();

  return (
    <Box sx={styles.root}>
      <Link to="/" aria-label={config.app_name}>
        <Logo width={128} height={64} />
      </Link>
      <Box>
        {(languages || []).map(({ code, label }) => (
          <Chip
            key={`lang_${code}`}
            label={label}
            onClick={() => navigate(`/${code}`)}
            avatar={<Avatar>{Flags[code]}</Avatar>}
            sx={styles.lang}
          />
        ))}
      </Box>
    </Box>
  );
};

export default Header;
