import { gql, useQuery } from "@apollo/client";

const QUERY = gql`
  query items($category: Int!){
    items(category: $category) {
      id
      name
      ingredients
      image
      price
    }
  }
`;


const useItems = category => {
  const { loading, error, data } = useQuery(QUERY, {
    skip: !parseInt(category),
    variables: { category: parseInt(category)},
  });

  return { loading, error, data: data?.items };
};

export default useItems;
