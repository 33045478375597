import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import Config from 'config';

const cache = new InMemoryCache();
const link = new HttpLink({
  uri: `${Config.api_url}/graphql`,
  fetch: (uri, options) => {
    const { body } = options;
    try {
      const { operationName } = JSON.parse(body);
      if (operationName) uri = `${uri}?${operationName}`;
    } catch (_error) {}

    return fetch(uri, options);
  },
});

const client = new ApolloClient({ cache, link });

export default client;
