import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "./fonts.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import registerServiceWorker from './registerServiceWorker';

import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "@mui/material";

import ApolloClient from "apollo/client";
import Theme from 'common/theme';
import { AppContextProvider } from "Context";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={Theme}>
      <ApolloProvider client={ApolloClient}>
        <AppContextProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AppContextProvider>
      </ApolloProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// service worker register
registerServiceWorker();