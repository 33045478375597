import { ReactComponent as Sr } from './sr.svg';
import { ReactComponent as En } from './en.svg';
import { ReactComponent as Ru } from './ru.svg';

const Flags = {
  sr: <Sr />,
  en: <En />,
  ru: <Ru />,
};

export default Flags;