import { useState } from 'react';
import {
  Box,
  Zoom,
} from '@mui/material';

import Food from './food';
import Drink from './drink';

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const Items = ({ menu, type = null }) => {
  const [zoomIn, _setZoomIn] = useState(true);

  const zoomTimeout = 450;
  const isFood = type === null;

  return (
    <Zoom in={zoomIn} timeout={zoomTimeout} mountOnEnter unmountOnExit>
      <Box sx={styles.root}>
        {isFood && <Food menu={menu} type={type} />}
        {!isFood && <Drink menu={menu} type={type} />}
      </Box>
    </Zoom>
  );
};

export default Items;
