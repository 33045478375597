import { Box, Typography, useMediaQuery } from '@mui/material';
import { currencyFormat } from 'utils';
import { useNavigate, useParams } from 'react-router-dom';

import theme from 'common/theme';
import DefaultImage from './image';
import Details from './details';

const styles = {
  root: {
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: '4vw',
    padding: 1,
  },
  image: {
    display: 'flex',
    height: 200,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    background: '#eee',

    '& img, & svg': {
      width: '100%',
      height: 'auto',
    },
  },
  title: {
    color: theme.palette.primary.main,
    display: 'flex',
    mt: 1,

    '& *': {
      display: 'flex',
    },
  },
  name: {
    flexGrow: 1,
    lineHeight: 1,
  },
  price: {
    margin: '0 !important',
  },
  ingredients: {
    textAlign: 'left',
    margin: '0 !important',

    '&:after': {
      content: '", "',
    },
    '&:last-of-type:after': {
      content: '""',
    },
  },
};

const Item = props => {
  const { id, name, image, price, ingredients, description } = props;
  const { type, id: categoryId, item_id = null } = useParams();

  const navigate = useNavigate();

  const handleClose = () => navigate(`/${type}/${categoryId}`);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  let imageComponent = <DefaultImage />;
  if (image) {
    imageComponent = <img src={image} alt={name} />;
  }

  const detailsProps = {
    name,
    image: imageComponent,
    price,
    ingredients,
    description,
    fullScreen,
    open: parseInt(item_id) === id,
    onClose: handleClose,
  };

  return (
    <Box sx={styles.root}>
      <Details {...detailsProps} />
      <Box
        sx={styles.image}
        onClick={() => navigate(`/${type}/${categoryId}/${id}`)}
      >
        {imageComponent}
      </Box>
      <Box sx={styles.title}>
        <Typography sx={styles.name} gutterBottom variant="h5">
          {name}
        </Typography>
        <Typography sx={styles.price}>{currencyFormat(price)}</Typography>
      </Box>
      <Typography
        sx={styles.ingredients}
        variant="body2"
        color="text.secondary"
      >
        {ingredients.join(', ')}
      </Typography>
    </Box>
  );
};

export default Item;
