import { Grid, Typography } from '@mui/material';
import { currencyFormat } from 'utils';

import originalStyles from '../styles.json';

const styles = {
  ...originalStyles,
  container: {
    ...originalStyles.container,
    borderBottom: '1px dotted #555',
  },
};

const Item = ({ item }) => {
  const { name, price } = item;
  return (
    <Grid container spacing={2} sx={styles.container}>
      <Grid item xs={8}>
        <Typography>{name}</Typography>
      </Grid>
      <Grid item xs={4}>
      <Typography sx={styles.price}>{currencyFormat(price)}</Typography>
      </Grid>
    </Grid>
  );
};

export default Item;
