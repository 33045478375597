import { gql, useQuery } from "@apollo/client";

const QUERY = gql`
  query category($category: Int!){
    category(id: $category) {
      id
      name
      image
    }
  }
`;


const useCategory = category => {
  const { loading, error, data } = useQuery(QUERY, {
    skip: !parseInt(category),
    variables: { category: parseInt(category)},
  });

  return { loading, error, data: data?.category };
};

export default useCategory;
