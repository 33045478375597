const data = {
  title: 'Питайся правильно, живи хорошо',
  col1: (
    <>
      Кормить людей - это не работа, это призвание.
      <br />
      А здоровая, вкусная, простая еда, приготовленная из свежих, натуральных
      продуктов – это для нас не тренд и не бизнес – это наша жизнь.
      <br />
      Это пища, которую мы готовим для себя и своих, и которой мы делимся с
      вами. Потому что ты тоже наш.
    </>
  ),
  col2: (
    <>
      Там, в Шолице, где мы празднуем жизнь и маленькие повседневные радости.
      <br />
      Туда, где и стар, и млад в свои лучшие годы.
      <br />
      Там, где мы встречаем друзей, соседей и знакомых, и где мы знакомимся с
      незнакомцами, прохожими и путешественниками.
    </>
  ),
  col3: (
    <>
      С теми, кто очень голоден, и с теми, кто просто хотел бы немного
      попробовать.
      <br />
      Туда, где наши гости становятся нашими друзьями. В Кубке.
    </>
  ),
  food: 'Еда',
  drink: 'Напитки',
};

export default data;
