import { useNavigate, useParams } from 'react-router-dom';
import { _useContext, useState } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  Typography,
  Zoom,
} from '@mui/material';
import Items from './items';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  list: {
    width: '100%',
  },
  item: {
    justifyContent: 'center',
    textTransform: 'uppercase',
  },
};

const Category = ({ id, name, onClick }) => (
  <ListItem>
    <ListItemButton onClick={() => onClick(id)} sx={styles.item}>
      <Typography variant="h2">{name}</Typography>
    </ListItemButton>
  </ListItem>
);

const Categories = ({ menu }) => {
  const navigate = useNavigate();
  const [zoomIn] = useState(true);
  const { id: typeId } = useParams();
  const categories = (menu || []).filter(({ type: { id } }) => {
    return id === parseInt(typeId);
  });
  const { type } = (categories ||[])[0] || {};
  const { linkToItems } = type || {};
  if (linkToItems) return <Items menu={menu} type={type} />;

  return (
    <Zoom in={zoomIn} timeout={450} mountOnEnter unmountOnExit>
      <Box sx={styles.root}>
        <List sx={styles.list}>
          {categories?.map(cat => (
            <Category
              key={`cat_${cat.id}`}
              {...cat}
              onClick={id => navigate(`/food/${id}`)}
            />
          ))}
        </List>
      </Box>
    </Zoom>
  );
};

export default Categories;
