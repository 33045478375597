import { gql, useQuery } from "@apollo/client";

const QUERY = gql`
  query categories {
    categories {
      id
      name
      image
    }
  }
`;


const useCategories = () => {
  const { loading, error, data } = useQuery(QUERY);

  return { loading, error, data: data?.categories };
};

export default useCategories;
