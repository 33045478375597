import { gql, useQuery } from "@apollo/client";

const QUERY = gql`
  query menu($lang: String!) {
    menu(lang: $lang) {
      id
      type {
        id
        name
        linkToItems
      }
      name
      image
      description
      items {
        id
        name
        image
        price
        ingredients
        description
      }
    }
    types(lang: $lang) {
      id
      name
      linkToItems
    }
  }
`;


const useMenu = lang => {
  const { loading, error, data } = useQuery(QUERY, {
    variables: { lang },
    skip: !Boolean(lang),
  });

  return { loading, error, data };
};

export default useMenu;
