import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { currencyFormat } from 'utils';
import theme from 'common/theme';

const styles = {
  name: {
    color: theme.palette.primary.main,
    fontSize: 24,
    fontWeight: 600,
  },
  image: {
    display: 'flex',
    height: 240,
    overflow: 'hidden',
    background: '#eee',
    justifyContent: 'center',
    alignItems: 'center',

    '& img, & svg': {
      width: '100%',
      height: 'auto',
    },
  },
  price: {
    float: 'right',
    color: theme.palette.primary.main,
    fontSize: 24,
    fontWeight: 600,
  },
  description: {
    whiteSpace: 'pre-line',
  },
  close: {
    float: 'right',
  },
};

const Details = ({
  open,
  onClose,
  fullScreen,
  name,
  image,
  price,
  ingredients,
  description,
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    fullScreen={fullScreen}
    keepMounted
    fullWidth
  >
    <DialogTitle>
      <Box component="span" sx={styles.name}>{name}</Box>
      <IconButton sx={styles.close} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <Box sx={styles.image}>{image}</Box>
      <DialogContentText>{ingredients.join(', ')}</DialogContentText>
      <Box sx={styles.description}>{description}</Box>
    </DialogContent>
    <DialogActions>
      <Box component="span" sx={styles.price}>
        {currencyFormat(price)}
      </Box>
    </DialogActions>
  </Dialog>
);

export default Details;
