const PUBLIC_URL = process.env.PUBLIC_URL;
const url = `${PUBLIC_URL}/sw.js`;
const register = () => {
  const publicUrl = new URL(PUBLIC_URL, window.location);
  if (publicUrl.origin !== window.location.origin) return;

  navigator.serviceWorker.register(url);
};

const onLoad = () => {
  window.addEventListener('load', register);
};

export default onLoad;
