const data = {
  title: 'Eath healhty, live well',
  col1: (
    <>
      Feeding people is not a job, it's a calling.
      <br />
      And healthy, tasty, simple food, prepared from fresh, natural ingredients
      ingredients for us is not a trend and not a business - it is our life.
      <br />
      It is the food we make for ourselves and ours, and which we share with you. Because you are ours too.
    </>
  ),
  col2: (
    <>
      There, in Šoljica, where we celebrate life and small, everyday pleasures.
      <br />
      There, where both old and young are in their best years.
      <br />
      There, where we meet friends, neighbors and acquaintances, and where we meet strangers, passers-by and travelers.
    </>
  ),
  col3: (
    <>
      With those who are really hungry and those who would just like to try a little.
      <br />
      There, where our guests become our friends.
    </>
  ),
  food: 'Food',
  drink: 'Drink',
};

export default data;
